import { inject, reactive, toRefs } from "vue";
import validationAlert from "@/utils/reusables/SwalAlert";
export default function adminHandler() {
  const axios = inject("$axios");
  const store = inject("$store");
  const Swal = inject("$swal");
  const moment = inject("$moment");
  const router = inject("$router");
  const state = reactive({
    loading: false,
    isLoading: false,
    countdown: 30,
    isRender: true,
  });

  const { errorHandler } = validationAlert();
  const createdClients = async (payment, amountInput) => {
    try {
      /*const timer = setInterval(() => {
        state.countdown--;
        if (state.countdown === 0) {
          clearInterval(timer);
          state.loading = false;
          state.countdown = 30; // Reset countdown
        }
      }, 1000);*/

      let transactionID;
      store.state.formData.paymentmethod = payment;
      let formDetails = store.state.formData;

      if (!store.state.ifRetrievedData) {
        const formData = new FormData();
        formData.append(
          "file",
          store.state.formData.uploadFile,
          store.state.formData.ModFilename
        );
        let result = await axios.post(`/meta/api/local/files`, formData);
        if (result.status === 200) {
          const { data } = await axios.post("/meta/api/clients", formDetails);
          if (data.status) {
            state.loading = false;
            transactionID = data.data.id;
            Swal.fire({
              position: "bottom",
              icon: "success",
              title:
                amountInput === 0
                  ? "Zero payment indicates that you did not pay any amount to your agent and your application is pending."
                  : "Your application has been processing.",
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if (amountInput) {
                  return router.push(
                    `/deped/alert/transaction/temp/meta/api/raw/${transactionID}`
                  );
                } else {
                  return router.push(
                    `/deped/alert/transaction/temp/meta/api/raw/hold/${data.data.referenceNumber}`
                  );
                }
              }
            });
          }
        }
      } else {
        Swal.fire({
          position: "bottom",
          icon: "success",
          title: "Your application has been processing.",
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            if (amountInput) {
              let transactionID = store.state.transactionId;
              return router.push(
                `/deped/alert/transaction/temp/meta/api/raw/${transactionID}`
              );
            }
          }
        });
      }
    } catch (error) {
      state.loading = false;
      errorHandler(error);
    }
  };
  const updateClients = async (id, reference) => {
    try {
      await axios.put(`/meta/api/clients/${id}`, {
        status: "paid",
        reference: reference,
      });
      Swal.fire({
        title: "Payment Paid",
        text: "The application has been completed.",
        icon: "success",
      });
      await axios.get(`/meta/api/auth/production?id=${id}`);
    } catch (error) {
      errorHandler(error);
    }
  };
  const updateTransaction = async (id, reference) => {
    try {
      let result = await axios.post(`/meta/api/auth/production?id=${id}`);
      if (result) {
        let res = await axios.put(`/meta/api/clients/${id}`, {
          status: "paid",
          reference: reference,
        });
        if (res) {
          state.isLoading = false;
          Swal.fire({
            title: "Payment Successful",
            text: "The application has been completed.",
            icon: "success",
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              return router.push(`/`);
            }
          });
        }
      }
    } catch (error) {
      state.isLoading = false;
      errorHandler(error);
    }
  };

  const OnlinePaymentProcess = async (reference) => {
    try {
      console.log(store.state.formData.contactnumber);
      const { data } = await axios.post("https://api.xendit.co/v2/invoices", {
        external_id: `IWC-${reference}`,
        amount: store.state.formData.premium,
        description: "IWC DEPED",
        capture: false,
        invoice_duration: 86400,
        customer: {
          given_names: store.state.formData.firstname,
          surname: store.state.formData.lastname,
          email: store.state.formData.email,
          mobile_number: store.state.formData.contactnumber,
          addresses: [
            {
              city: store.state.formData.homeaddressOne,
              country: "Philippines",
            },
          ],
        },
        customer_notification_preference: {
          invoice_created: ["email"],
          invoice_reminder: ["email"],
          invoice_paid: ["email"],
        },
        success_redirect_url: `${process.env.VUE_APP_BASE_URL}#/deped/alert/transaction/temp/meta/api/raw/releasing/${reference}`,

        failure_redirect_url: process.env.VUE_APP_BASE_URL,
        currency: "PHP",
        items: [
          {
            name: "Teacher Plan",
            quantity: 1,
            price: store.state.formData.premium,
            category: "Healthcard",
            url: "https://app.iwcdeped.com/#/",
          },
        ],
        payment_methods: [
          "CREDIT_CARD",
          "DD_BPI",
          "DD_UBP",
          "DD_RCBC",
          "DD_BDO_EPAY",
          "DP_MLHUILLIER",
          "DP_PALAWAN",
          "DP_ECPAY_LOAN",
          "PAYMAYA",
          "GRABPAY",
          "GCASH",
          "SHOPEEPAY",
          "BILLEASE",
          "CASHALO",
          "BDO_ONLINE_BANKING",
          "BPI_ONLINE_BANKING",
          "UNIONBANK_ONILNE_BANKING",
          "BOC_ONLINE_BANKING",
          "CHINABANK_ONLINE_BANKING",
          "INSTAPAY_ONLINE_BANKING",
          "LANDBANK_ONLINE_BANKING",
          "MAYBANK_ONLINE_BANKING",
          "METROBANK_ONLINE_BANKING",
          "PNB_ONLINE_BANKING",
          "PSBANK_ONLINE_BANKING",
          "PESONET_ONLINE_BANKING",
          "RCBC_ONLINE_BANKING",
          "ROBINSONS_BANK_ONLINE_BANKING",
          "SECURITY_BANK_ONLINE_BANKING",
          "QRPH",
        ],
      });
      window.open(data.invoice_url, "_self");
    } catch (error) {
      errorHandler(error);
    }
  };

  const createdClientsOnline = async (reference) => {
    try {
      let paymentmethodid = 2;
      let transactionID;
      store.state.formData.paymentmethod = "Online Payment";
      let formDetails = store.state.formData;

      const { data } = await axios.post("/meta/api/clients", {
        agentcode: formDetails.agentcode,
        plantypeid: formDetails.plantypeid,
        premium: formDetails.premium,
        payorname: formDetails.payorname,
        relationship: formDetails.relationship,
        membername: null,
        lastname: formDetails.lastname,
        firstname: formDetails.firstname,
        middlename: formDetails.middlename,
        suffix: formDetails.suffix,
        birthdate: formDetails.birthdate,
        civilstatus: formDetails.civilstatus,
        contactnumber: formDetails.contactnumber,
        email: formDetails.email,
        homeaddressOne: formDetails.homeaddressOne,
        homeaddressTwo: formDetails.homeaddressTwo,
        paymentmethod: formDetails.paymentmethod,
        paymentmethodid: paymentmethodid,
        status: "paid",
      });
      transactionID = data.data.id;

      //insert

      updateTransactionOnline(transactionID, reference);
    } catch (error) {
      console.log(error);
      state.loading = false;
      errorHandler(error);
    }
  };
  const updateTransactionOnline = async (id, reference) => {
    try {
      let result = await axios.post(`/meta/api/auth/production?id=${id}`);
      if (result) {
        let res = await axios.put(`/meta/api/clients/${id}`, {
          status: "complete",
          reference: reference,
          datepayment: moment(),
        });
        if (res.data.status) {
          state.isRender = false;
          Swal.fire({
            title: "Your payment was completed successfully",
            text: "Check your email for your virtual card. Thank you!",
            icon: "success",
            showConfirmButton: true,
            allowOutsideClick: false,
            background: "var(--primary-color)",
            color: "var(--light-color)",
            confirmButtonColor: "var(--secondary-color)",
          }).then((result) => {
            if (result.isConfirmed) {
              store.commit("NewApplicationHandler");
              //localStorage.clear();
              store.commit("resetState");
              return router.push(`/`);
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
      errorHandler(error);
    }
  };

  return {
    ...toRefs(state),
    createdClients,
    updateClients,
    updateTransaction,
    errorHandler,
    OnlinePaymentProcess,
    createdClientsOnline,
    updateTransactionOnline,
  };
}
