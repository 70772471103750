import axios from "axios";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.request.use(
  function (config) {
    config.headers["x-secret-key"] = process.env.VUE_APP_SECRET_KEY;

    //set xendit authorization header
    config.headers.Authorization =
      process.env.VUE_APP_PAYMENT_INTEGRATION_GATEWAY_API_PROD;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
