<template>
  <div class="wrapper">
    <div class="container">
      <div class="divider">
        <div class="header-icon">
          <greetingSVG id="svg" />
          <div class="header-message">
            <h2>You're almost done!</h2>
            <p>Please wait while we prepare your card...</p>
          </div>
          <div class="gif-container" v-if="isRender">
            <app-loading-sending />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import greetingSVG from "@/components/svgs/greetingSVG.vue";
import ProcessData from "@/data/ProcessData";
import { useRoute } from "vue-router";
import { inject, onMounted } from "vue";
export default {
  components: {
    greetingSVG,
  },
  setup() {
    const { createdClientsOnline, isRender } = ProcessData();
    const router = inject("$router");
    const store = inject("$store");
    const route = useRoute();
    const referenceNumber = route.params.id;

    onMounted(() => {
      let newApp = store.state.isNewApplication;
      if (newApp) {
        router.push({ name: "index" });
      } else {
        setTimeout(() => {
          createdClientsOnline(referenceNumber);
        }, 4000);
      }
    });
    return {
      referenceNumber,
      isRender,
    };
  },
};
</script>
<style scoped>
.divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.header-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.header-message {
  text-align: center;
  padding: 0 20px;
}

.header-message h2 {
  color: var(--accent-color);
  margin: 15px 0;
  font-size: 24px;
}

.header-message p {
  line-height: 1.6;
  color: var(--semi-light-color);
  font-size: 16px;
  text-indent: 8%;
  margin: 0;
}
#svg {
  margin: 30px 0;
  height: 140px;
  width: 140px;
}
.gif-container {
  margin: 14px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.gif-container img {
  height: 140px;
  width: 140px;
}
</style>
